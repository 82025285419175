import React from 'react'
import { AiOutlineHome, AiFillQuestionCircle, AiOutlineOrderedList, AiOutlineEnvironment, AiOutlineArrowRight } from 'react-icons/ai'

const error = () => {
  return (
    <>
      <div className="flex items-center justify-center min-h-screen bg-white dark:bg-jertt-650 py-48">
      <div className="flex flex-col">

          {/* <!-- Error Container --> */}
          <div className="flex flex-col items-center">
              <div className="text-jertt dark:text-zinc-50 font-bold text-7xl">
                  404
              </div>

              <div className="font-bold dark:text-zinc-200 text-3xl xl:text-7xl lg:text-6xl md:text-5xl mt-10">
              Kjo faqe nuk ekziston
              </div>

              <div className="text-gray-400 dark:text-gray-200 font-medium text-sm md:text-xl lg:text-2xl mt-8">
              Faqja që po kërkoni nuk u gjet.
              </div>
          </div>

          {/* <!-- Continue With --> */}
          <div className="flex flex-col mt-48">
              <div className="text-gray-400 dark:text-gray-200 font-bold uppercase">
                  Vazhdo Me
              </div>

              <div className="flex flex-col items-stretch mt-5">
                  {/* <!-- Nav Item #1 --> */}
                  <div className="flex flex-row group px-4 py-8
                      border-t hover:cursor-pointer
                      transition-all duration-200 delay-100">

                      {/* <!-- Nav Icon --> */}
                      <div className="rounded-xl bg-blue-100  px-5 py-2 md:py-4">
                          <AiOutlineHome className="text-jertt-600 text-2xl md:text-4xl"/>
                      </div>

                      {/* <!-- Text --> */}
                      <div className="grow flex flex-col pl-5 pt-2">
                          <div className="font-bold text-sm md:text-lg lg:text-xl group-hover:underline dark:text-white">
                              Ballina
                          </div>

                          <div className="font-semibold text-sm md:text-md lg:text-lg
                              text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-200
                              transition-all duration-200 delay-100">
                              Gjithçka fillon këtu
                          </div>
                      </div>

                      {/* <!-- Chevron --> */}
                      <AiOutlineArrowRight className=" text-gray-400 w-12 my-auto pr-2
                          group-hover:text-gray-700 dark:group-hover:text-gray-200 transition-all duration-200 delay-100"/>
                  </div>

                  {/* <!-- Nav Item #2 --> */}
                  <div className="flex flex-row group px-4 py-8
                      border-t hover:cursor-pointer
                      transition-all duration-200 delay-100">

                      {/* <!-- Nav Icon --> */}
                      <div className="rounded-xl bg-blue-100 px-5 py-2 md:py-4">
                      <AiOutlineOrderedList className="mx-auto text-jertt-600 text-2xl md:text-4xl"/>
                      </div>

                      {/* <!-- Text --> */}
                      <div className="grow flex flex-col pl-5 pt-2">
                          <div className="font-bold text-sm md:text-lg lg:text-xl group-hover:underline dark:text-white">
                              Sherbimet
                          </div>

                          <div className="font-semibold text-sm md:text-md lg:text-lg
                              text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-200
                              transition-all duration-200 delay-100">
                              Shikoni shërbimet tona
                          </div>
                      </div>

                      {/* <!-- Chevron --> */}
                      <AiOutlineArrowRight className=" text-gray-400 w-12 my-auto pr-2
                          group-hover:text-gray-700 dark:group-hover:text-gray-200 transition-all duration-200 delay-100"/>
                  </div>

                  {/* <!-- Nav Item #3 --> */}
                  <div className="flex flex-row group px-4 py-8
                      border-t hover:cursor-pointer
                      transition-all duration-200 delay-100">

                      {/* <!-- Nav Icon --> */}
                      <div className="rounded-xl bg-blue-100 px-5 py-2 md:py-4">
                      <AiFillQuestionCircle className="mx-auto text-jertt-600 text-2xl md:text-4xl"/>
                      </div>

                      {/* <!-- Text --> */}
                      <div className="grow flex flex-col pl-5 pt-2">
                          <div className="font-bold text-sm md:text-lg lg:text-xl group-hover:underline dark:text-white">
                              Rreth Ne
                          </div>

                          <div className="font-semibold text-sm md:text-md lg:text-lg
                              text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-200
                              transition-all duration-200 delay-100">
                              Mësoni më shumë rreth nesh
                          </div>
                      </div>

                      {/* <!-- Chevron --> */}
                      <AiOutlineArrowRight className=" text-gray-400 w-12 my-auto pr-2
                          group-hover:text-gray-700 dark:group-hover:text-gray-200 transition-all duration-200 delay-100"/>
                  </div>

                  {/* <!-- Nav Item #3 --> */}
                  <div className="flex flex-row group px-4 py-8
                      border-t hover:cursor-pointer
                      transition-all duration-200 delay-100">

                      {/* <!-- Nav Icon --> */}
                      <div className="rounded-xl bg-blue-100 px-5 py-2 md:py-4">
                      <AiOutlineEnvironment className="mx-auto text-jertt-600 text-2xl md:text-4xl"/>
                      </div>

                      {/* <!-- Text --> */}
                      <div className="grow flex flex-col pl-5 pt-2">
                          <div className="font-bold text-sm md:text-lg lg:text-xl group-hover:underline dark:text-white">
                              Kontakti
                          </div>

                          <div className="font-semibold text-sm md:text-md lg:text-lg
                              text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-200
                              transition-all duration-200 delay-100">
                              Na kontaktoni për pyetjet tuaja
                          </div>
                      </div>

                      {/* <!-- Chevron --> */}
                      <AiOutlineArrowRight className=" text-gray-400 w-12 my-auto pr-2
                          group-hover:text-gray-700 dark:group-hover:text-gray-200 transition-all duration-200 delay-100"/>
                  </div>
              </div>
          </div>
      </div>
  </div>
    </>
  )
}

export default error