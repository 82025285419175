import React, { Component } from 'react'
import WOW from 'wowjs';

export default class Location extends Component {

// Defining WOW 
componentDidMount() {
    new WOW.WOW({
      live: false
  }).init();
}

  render() {
    return (
      <>
        <section className='text-gray-600 max-w-screen overflow-hidden'>
            <div className='pt-10 text-center'>
                <h3 className='mb-4 md:text-3xl md:leading-normal text-2xl text-jertt-800 leading-normal font-semibold wow fadeInUp' data-wow-delay='0.2s'>Ku mund te na vizitoni</h3>
                <p className='text-muted-400 max-w-xl mx-auto px-4 text-jertt-600 wow fadeInUp' data-wow-delay='0.2s'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati provident saepe minus</p>
            </div>
          <div className='container md:py-24 py-16 mx-auto flex flex-wrap justify-center'>
            <div className='flex flex-wrap'>
              <div className='px-8 lg:w-1/2 md:w-full max-w-screen'>
                <div className='flex justify-center m-8 sm:flex-row flex-col wow fadeInLeft' data-wow-delay='0.2s'>
                  <div className='w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-jertt-450 text-white flex-shrink-0'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='w-8 h-8' viewBox='0 0 16 16'>
                      <path d='M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z'/>
                      <path d='M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z'/>
                    </svg>
                  </div>
                  <div className='flex-grow'>
                    <h2 className='text-gray-900 text-lg title-font font-medium mb-3'>Fushë Kosovë</h2>
                    <p className='leading-relaxed text-base truncate'>Art Roleta, XK, Nënë Tereza, Fushë Kosovë</p>
                    <a className='mt-3 text-jertt-500 inline-flex items-center hover:text-jertt-700 ease-linear duration-200' href='https://goo.gl/maps/EJeW7SMQTCRLZMjJA' target='_blank' rel='noreferrer'>Shko ne google maps
                      <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' className='w-4 h-4 ml-2' viewBox='0 0 24 24'>
                        <path d='M5 12h14M12 5l7 7-7 7'></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div className='px-8 lg:w-1/2 md:w-full max-w-screen wow fadeInRight' data-wow-delay='0.2s'>
                <div className='flex justify-center m-8 sm:flex-row flex-col max-w-screen'>
                  <div className='w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-jertt-450 text-white flex-shrink-0'>
                  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='w-8 h-8' viewBox='0 0 16 16'>
                      <path d='M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z'/>
                      <path d='M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z'/>
                    </svg>
                  </div>
                  <div className='flex-grow'>
                    <h2 className='text-gray-900 text-lg title-font font-medium mb-3'>Prishtinë</h2>
                    <p className='leading-relaxed text-base truncate'>Art Roleta, XK, Kodra e trimave, Prishtinë</p>
                    <a className='mt-3 text-jertt-500 inline-flex items-center hover:text-jertt-700 ease-linear duration-200' href='https://g.page/ArtRoleta?share' target='_blank' rel='noreferrer'>Shko ne google maps
                      <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' className='w-4 h-4 ml-2' viewBox='0 0 24 24'>
                        <path d='M5 12h14M12 5l7 7-7 7'></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}
