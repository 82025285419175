import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ScrollToTop from './hooks/ScrollToTop';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/home';
import Galeria from './pages/galeria';
import Services from './pages/services';
import About from './pages/about';
import Contact from './pages/contact';
import Error from './pages/error';
import Xhama from './pages/xhama';
import Roleta from './pages/roleta';

function App() {
  return (
    <Router>
        <ScrollToTop />
      <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/galeria' element={<Galeria />} />
          <Route path='/sherbime' element={<Services />} />
          <Route path='/rreth_ne' element={<About />} />
          <Route path='/kontakt' element={<Contact />} />
          <Route path='/xhama' element={<Xhama />} />
          <Route path='/roleta' element={<Roleta />} />
          <Route path='/*' element={<Error />} />
        </Routes>
      <Footer />
    </Router>
  );
}

export default App;
