import React from 'react'
import Lightbox from '../components/Lightbox'


export default function galeria() {
  return (
    <Lightbox />
  )
}

