import React, { Component } from 'react'
import WOW from 'wowjs';
import { Link } from 'react-router-dom';

export default class Services extends Component {

// Defining WOW 
componentDidMount() {
    new WOW.WOW({
      live: false
  }).init();
}

  render() {
    return (
      <>
        <section className='text-gray-600 body-font overflow-hidden'>
          <div className='container px-5 py-24 mx-auto'>
            <div className='text-center mb-20'>
              <h1 className='sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 wow fadeInUp' data-wow-delay='0.1s'>Shërbimet që ofron Art Roleta</h1>
              <p className='text-base font-semibold leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto uppercase wow fadeInUp' data-wow-delay='0.2s'>Ndërmarrje e specializuar <span className='normal-case font-medium'>për prodhimin dhe montimin e:</span></p>
            </div>
            <div className='flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2'>
              <div className='p-2 sm:w-1/2 w-full wow fadeInLeft' data-wow-delay='0.1s'>
              <a href="/roleta">
                <div className='bg-gray-100 rounded flex justify-between p-4 h-full items-center'>
                  <div className="flex items-center">
                    <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6 flex-shrink-0 mr-4' viewBox='0 0 24 24'>
                      <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                      <path d='M22 4L12 14.01l-3-3'></path>
                    </svg>
                    <span className='title-font font-medium'>Roleta të jashtme <span className='font-semibold'> Alumin &amp; PVC</span></span>
                  </div>
                  <div className="flex animate-bounce">
                    <svg fill='currentColor' stroke='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6' viewBox='0 0 16 16'>
                    <path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1zM8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5.114 5.114 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.632 2.632 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046l-.048.002zm2.094 2.025z"/>
                    </svg>
                  </div>
                </div>
              </a>
              </div>
              <div className='p-2 sm:w-1/2 w-full wow fadeInRight' data-wow-delay='0.1s'>
              <a href="/xhama">
                <div className='bg-gray-100 rounded flex justify-between p-4 h-full items-center'>
                  <div className="flex items-center">
                  <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6 flex-shrink-0 mr-4' viewBox='0 0 24 24'>
                    <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                    <path d='M22 4L12 14.01l-3-3'></path>
                  </svg>
                  <span className='title-font font-medium'>Xhama <span className='font-semibold'>lëvizës</span> <span className='text-gray-700/70'>{'('}8mm{')'}</span></span>
                  </div>
                  <div className="flex animate-bounce">
                    <svg fill='currentColor' stroke='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6' viewBox='0 0 16 16'>
                      <path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1zM8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5.114 5.114 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.632 2.632 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046l-.048.002zm2.094 2.025z"/>
                    </svg>
                  </div>
                </div>
              </a>
              </div>
              <div className='p-2 sm:w-1/2 w-full wow fadeInLeft' data-wow-delay='0.2s'>
                <a href="/xhama">
                <div className='bg-gray-100 rounded flex justify-between p-4 h-full items-center'>
                  <div className="flex items-center">
                  <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6 flex-shrink-0 mr-4' viewBox='0 0 24 24'>
                    <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                    <path d='M22 4L12 14.01l-3-3'></path>
                  </svg>
                  <span className='title-font font-medium'>Xhama <span className='font-semibold'>sllide</span> <span className='text-gray-700/70'>{'('}rrëshqitës{')'}</span></span>
                  </div>
                  <div className="flex animate-bounce">
                    <svg fill='currentColor' stroke='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6' viewBox='0 0 16 16'>
                      <path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1zM8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5.114 5.114 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.632 2.632 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046l-.048.002zm2.094 2.025z"/>
                    </svg>
                  </div>
                </div>
                </a>
              </div>
              <div className='p-2 sm:w-1/2 w-full wow fadeInRight' data-wow-delay='0.2s'>
                <a href="/galeria">
                <div className='bg-gray-100 rounded flex p-4 h-full items-center'>
                  <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6 flex-shrink-0 mr-4' viewBox='0 0 24 24'>
                    <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                    <path d='M22 4L12 14.01l-3-3'></path>
                  </svg>
                  <span className='title-font font-medium'>Veneciane <span className='font-bold'>druri</span> <span className='text-gray-700/70'>{'('}5cm{')'}</span></span>
                </div>
                </a>
              </div>
              <div className='p-2 sm:w-1/2 w-full wow fadeInLeft' data-wow-delay='0.3s'>
                <a href="/galeria">
                <div className='bg-gray-100 rounded flex p-4 h-full items-center'>
                  <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6 flex-shrink-0 mr-4' viewBox='0 0 24 24'>
                    <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                    <path d='M22 4L12 14.01l-3-3'></path>
                  </svg>
                  <span className='title-font font-medium'>Veneciane <span className='font-bold'>alumin</span> <span className='text-gray-700/70'>{'('}2.5cm &amp; 5cm{')'}</span></span>
                </div>
                </a>
              </div>
              <div className='p-2 sm:w-1/2 w-full wow fadeInRight' data-wow-delay='0.3s'>
                <a href="/galeria">
                <div className='bg-gray-100 rounded flex p-4 h-full items-center'>
                  <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6 flex-shrink-0 mr-4' viewBox='0 0 24 24'>
                    <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                    <path d='M22 4L12 14.01l-3-3'></path>
                  </svg>
                  <span className='title-font font-medium'>Rrjeta <span className='font-bold'>kundër insekteve</span> <span className='text-gray-700/70'>{'('}1.7cm &amp;2.5cm{')'}</span></span>
                </div>
                </a>
              </div>
              <div className='p-2 sm:w-1/2 w-full wow fadeInLeft' data-wow-delay='0.2s'>
                <a href="/galeria">
                <div className='bg-gray-100 rounded flex p-4 h-full items-center'>
                  <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6 flex-shrink-0 mr-4' viewBox='0 0 24 24'>
                    <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                    <path d='M22 4L12 14.01l-3-3'></path>
                  </svg>
                  <span className='title-font font-medium'>Perde <span className='font-bold'>zebra</span></span>
                </div>
                </a>
              </div>
              <div className='p-2 sm:w-1/2 w-full wow fadeInRight' data-wow-delay='0.2s'>
                <a href="/galeria">
                <div className='bg-gray-100 rounded flex p-4 h-full items-center'>
                  <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6 flex-shrink-0 mr-4' viewBox='0 0 24 24'>
                    <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                    <path d='M22 4L12 14.01l-3-3'></path>
                  </svg>
                  <span className='title-font font-medium'>Perde <span className='font-bold'>rrolle</span></span>
                </div>
                </a>
              </div>
              <div className='p-2 sm:w-1/2 w-full wow fadeInLeft' data-wow-delay='0.2s'>
                <a href="/galeria">
                <div className='bg-gray-100 rounded flex p-4 h-full items-center'>
                  <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6 flex-shrink-0 mr-4' viewBox='0 0 24 24'>
                    <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                    <path d='M22 4L12 14.01l-3-3'></path>
                  </svg>
                  <span className='title-font font-medium'>Perde <span className='font-bold'>shiritore</span></span>
                </div>
                </a>
              </div>
              <div className='p-2 sm:w-1/2 w-full wow fadeInRight' data-wow-delay='0.2s'>
                <a href="/galeria">
                <div className='bg-gray-100 rounded flex p-4 h-full items-center'>
                  <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6 flex-shrink-0 mr-4' viewBox='0 0 24 24'>
                    <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                    <path d='M22 4L12 14.01l-3-3'></path>
                  </svg>
                  <span className='title-font font-medium'>Perde <span className='font-bold'>plise</span></span>
                </div>
                </a>
              </div>
              <div className='p-2 sm:w-1/2 w-full wow fadeInLeft' data-wow-delay='0.2s'>
                <a href="/galeria">
                <div className='bg-gray-100 rounded flex p-4 h-full items-center'>
                  <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6 flex-shrink-0 mr-4' viewBox='0 0 24 24'>
                    <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                    <path d='M22 4L12 14.01l-3-3'></path>
                  </svg>
                  <span className='title-font font-medium'>Perde <span className='font-bold'>shangrila</span></span>
                </div>
                </a>
              </div>
              <div className='p-2 sm:w-1/2 w-full wow fadeInRight' data-wow-delay='0.2s'>
                <a href="/galeria">
                <div className='bg-gray-100 rounded flex p-4 h-full items-center'>
                  <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6 flex-shrink-0 mr-4' viewBox='0 0 24 24'>
                    <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                    <path d='M22 4L12 14.01l-3-3'></path>
                  </svg>
                  <span className='title-font font-medium'>Perde <span className='font-bold'>hanas</span></span>
                </div>
                </a>
              </div>
              <div className='p-2 sm:w-1/2 w-full wow fadeInLeft' data-wow-delay='0.2s'>
                <a href="/galeria">
                <div className='bg-gray-100 rounded flex p-4 h-full items-center'>
                  <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6 flex-shrink-0 mr-4' viewBox='0 0 24 24'>
                    <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                    <path d='M22 4L12 14.01l-3-3'></path>
                  </svg>
                  <span className='title-font font-medium'>Perde <span className='font-bold'>bambus</span></span>
                </div>
                </a>
              </div>
              <div className='p-2 sm:w-1/2 w-full wow fadeInRight' data-wow-delay='0.2s'>
                <a href="/galeria">
                <div className='bg-gray-100 rounded flex p-4 h-full items-center'>
                  <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6 flex-shrink-0 mr-4' viewBox='0 0 24 24'>
                    <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                    <path d='M22 4L12 14.01l-3-3'></path>
                  </svg>
                  <span className='title-font font-medium'>Dyer <span className='font-bold'>harmonikë</span></span>
                </div>
                </a>
              </div>
              <div className='p-2 sm:w-1/2 w-full wow fadeInLeft' data-wow-delay='0.2s'>
                <a href="/galeria">
                <div className='bg-gray-100 rounded flex p-4 h-full items-center'>
                  <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6 flex-shrink-0 mr-4' viewBox='0 0 24 24'>
                    <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                    <path d='M22 4L12 14.01l-3-3'></path>
                  </svg>
                  <span className='title-font font-medium'>Dyer <span className='font-bold'>garazhdave nga alumini </span> <span className='text-gray-700/70'>me telekomandë {'('}77mm{')'}</span></span>
                </div>
                </a>
              </div>

            </div>
            <Link to="/kontakt">
              <button className='flex mx-auto mt-16 text-white bg-jertt border-0 py-2 px-8 focus:outline-none hover:bg-jertt-600 ease-linear duration-300 rounded text-lg'>Kontakto Tani</button>
            </Link>
          </div>
        </section>
      </>
    )
  }
}
