import React from 'react'
import HeroSlider from '../components/HeroSlider'
import Cta from '../components/Cta'
import Prods from '../components/Prods'
import Location from '../components/Location'


const home = () => {
  return (
    <>
        <HeroSlider />
        <Cta />
        <Prods />
        <Location />
    </>
  )
}

export default home