import React, { Component } from 'react'
import WOW from 'wowjs';

export default class Contact extends Component {
    
// Defining WOW 
componentDidMount() {
    new WOW.WOW({
      live: false
  }).init();
}

  render() {
    return (
      <>
        
        <section class='text-gray-600 body-font relative pt-24 overflow-hidden'>
            <div class='container px-5 py-4 mx-auto'>
              <div class='flex flex-col text-center w-full mb-12'>
                <h1 class='sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900 wow fadeInUp' data-wow-delay='0.1s'>Kontakti</h1>
                <p class='lg:w-2/3 mx-auto leading-relaxed text-base lg:px-56 wow fadeInUp' data-wow-delay='0.2s'>Na kontaktoni përmes formularit të kontaktit ose na vizitoni në një nga vendndodhjet tona të shfaqura më poshtë!</p>
                </div>
                <div class='lg:w-1/2 md:w-2/3 mx-auto'>
                <div class='flex flex-wrap -m-2'>
                    <div class='p-2 w-1/2 wow fadeInLeft' data-wow-delay='0.2s'>
                    <div class='relative'>
                        <label for='name' class='leading-7 text-sm text-gray-600'>Name</label>
                        <input type='text' id='name' name='name' class='w-full bg-gray-100 bg-opacity-50  rounded border border-gray-300 focus:border-jertt-500 focus:bg-white focus:ring-2 focus:ring-jertt-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'/>
                    </div>
                    </div>
                    <div class='p-2 w-1/2 wow fadeInRight' data-wow-delay='0.3s'>
                    <div class='relative'>
                        <label for='email' class='leading-7 text-sm text-gray-600'>Email</label>
                        <input type='email' id='email' name='email' class='w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-jertt-500 focus:bg-white focus:ring-2 focus:ring-jertt-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'/>
                    </div>
                    </div>
                    <div class='p-2 w-full wow fadeInUp' data-wow-delay='0.3s'>
                    <div class='relative'>
                        <label for='message' class='leading-7 text-sm text-gray-600'>Message</label>
                        <textarea id='message' name='message' class='w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-jertt-500 focus:bg-white focus:ring-2 focus:ring-jertt-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out'></textarea>
                    </div>
                    </div>
                    <div class='p-2 w-full wow fadeInUp' data-wow-delay='0.4s'>
                    <button class='flex mx-auto text-white bg-jertt-500 border-0 py-2 px-8 focus:outline-none hover:bg-jertt-600 rounded text-lg'>Dërgo</button>
                    </div>
                    <div class='p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center wow fadeInUp' data-wow-delay='0.2s'>
                    <a class='text-jertt-500' href='mailto:info@artroleta.com'>info@artroleta.com</a>
                    <p class='leading-normal mb-5'>
                        <br/><a href='tel:+383-45-382-512'>+383 45 382 512</a>{' | '}<a href='tel:383-44-515-510'>+383 44 515 510</a>
                    </p>
                    <span class='inline-flex'>
                        <a class='text-gray-500' href='https://www.facebook.com/artroleta/' target='_blank' rel='noreferrer'>
                        <svg fill='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' class='w-5 h-5' viewBox='0 0 24 24'>
                            <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                        </svg>
                        </a>
                        <a class='ml-4 text-gray-500' href='https://www.instagram.com/artroleta/' target='_blank' rel='noreferrer'>
                        <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' class='w-5 h-5' viewBox='0 0 24 24'>
                            <rect width='20' height='20' x='2' y='2' rx='5' ry='5'></rect>
                            <path d='M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01'></path>
                        </svg>
                        </a>
                    </span>
                    </div>
                </div>
                </div>
            </div>
        </section>
        <section className=''>
          <div className='px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20'>
            <div className='grid max-w-screen-lg gap-8 row-gap-5 md:row-gap-8 sm:mx-auto lg:grid-cols-2'>
              <div className='transition duration-300 transform bg-white  rounded shadow-md shadow-gray-400/50 hover:-translate-y-1 hover:shadow md:text-center wow fadeInLeft' data-wow-delay='0.2s'>
                <div className='relative'>
                    <iframe
                        className='object-cover w-full h-64 rounded-t lg:h-80 xl:h-96'
                        src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11739.874253446285!2d21.1037045!3d42.6408264!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x49963285053ea004!2sArt%20Roleta!5e0!3m2!1sen!2s!4v1660226564846!5m2!1sen!2s'
                        title='Location Frame'></iframe>
                    <div className='absolute inset-0 bg-gray-800 bg-opacity-25' />
                </div>
                <div className='px-6 py-8 border border-t-0 rounded-b sm:px-8'>
                    <h5 className='mb-2 text-xl font-bold leading-none sm:text-2xl '>
                    Fushë Kosovë
                    </h5>
                    <p className='mb-5 text-gray-700 '>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium!
                    </p>
                    <a
                    type='submit' href='https://goo.gl/maps/EJeW7SMQTCRLZMjJA'  target='_blank' rel='noreferrer'
                    className='inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-jertt hover:bg-jertt-650 focus:shadow-outline focus:outline-none'>
                        Directions
                    </a>
                </div>
                </div>
                <div className='transition duration-300 transform bg-white rounded shadow-md shadow-gray-400/50 hover:-translate-y-1 hover:shadow md:text-center wow fadeInRight' data-wow-delay='0.2s'>
                <div className='relative'>
                    <iframe
                        className='object-cover w-full h-64 rounded-t lg:h-80 xl:h-96'
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d616.5196429613818!2d21.15893798523581!3d42.689358819134576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549f6c7216e795%3A0x3b04b692d57d9844!2sArt%20Roleta%2C%20Kodra%20e%20trimave%2C%20Prishtin%C3%AB!5e0!3m2!1sen!2s!4v1660226415504!5m2!1sen!2s'
                        title='Location Frame'></iframe>
                    <div className='absolute inset-0 bg-gray-800 bg-opacity-25' />
                </div>
                <div className='px-6 py-8 border border-t-0 rounded-b sm:px-8'>
                    <h5 className='mb-2 text-xl font-bold leading-none sm:text-2xl'>
                    Prishtinë
                    </h5>
                    <p className='mb-5 text-gray-700'>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium!
                    </p>
                    <a
                    type='submit' href='https://goo.gl/maps/rgSxNhbfdrKKUf2f8'  target='_blank' rel='noreferrer'
                    className='inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-jertt hover:bg-jertt-650 focus:shadow-outline focus:outline-none'
                    >
                    Directions
                    </a>
                </div>
                </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}
