import React, { Component } from 'react'
import Logo from '../assets/logo.svg'
// import {
//   FaFacebookSquare,
//   FaInstagram,
// } from 'react-icons/fa';

export default class Footer extends Component {
  render() {
    return (
      <>
        <footer className="p-4 bg-jertt shadow-sm shadow-gray-400 md:px-6 md:py-8  bg-texx13 bg-repeat ">
            <div className="sm:flex sm:items-center sm:justify-between">
                <a href="/" className="flex items-center mb-4 sm:mb-0">
                    <img src={Logo} className="mr-3 h-8 select-none" alt="ArtRoleta Logo"/>
                    {/* <span className="self-center text-2xl font-semibold whitespace-nowrap">Jerttinjo</span> */}
                </a>
                <ul className="flex flex-wrap items-center mb-6 text-sm text-white sm:mb-0 d">
                    <li>
                        <a href="/" className="mr-4 hover:text-zinc-400 ease-linear duration-200 md:mr-6 ">Ballina</a>
                    </li>
                    <li>
                        <a href="/galeria" className="mr-4 hover:text-zinc-400 ease-linear duration-200 md:mr-6">Galeria</a>
                    </li>
                    <li>
                        <a href="/sherbime" className="mr-4 hover:text-zinc-400 ease-linear duration-200 md:mr-6">Sherbime</a>
                    </li>
                    <li>
                        <a href="/rreth_ne" className="mr-4 hover:text-zinc-400 ease-linear duration-200 md:mr-6 ">Rreth Ne</a>
                    </li>
                    <li>
                        <a href="/kontakt" className="hover:text-zinc-400 ease-linear duration-200">Kontakti</a>
                    </li>
                </ul>
            </div>
            <hr className="my-6 border-white sm:mx-auto lg:my-8"/>
            <span className="block text-base text-gray-200 sm:text-center ">© {new Date().getFullYear()} Art Roleta — <a href="https://3ambrand.com/" target="_blank" rel='noreferrer'  className="btn btn-link text-gray-100 hover:text-gray-200 after:bg-gray-100 transition duration-500">3AM Brand Communication {/* Created by Jertt | Jeart Thaqi | jertt.dev */} </a>
            </span>
        </footer>
      </>
    )
  }
}