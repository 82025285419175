import React, { Component } from 'react'
import { AiOutlineCheckCircle, AiOutlineSetting } from 'react-icons/ai'
import Blinds1 from '../assets/about/blinds1.webp'
import Blinds2 from '../assets/about/blinds2.webp'
import Blinds3 from '../assets/about/blinds3.webp'
import WOW from 'wowjs';


export default class About extends Component {
  
  // Defining WOW 
componentDidMount() {
  new WOW.WOW({
    live: false
}).init();
}

  render() {
    return (
      <>
        <section className='overflow-hidden'>
            <div className='px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20'>
              <div className='grid gap-12 row-gap-8 lg:grid-cols-2'>
                <div className='flex flex-col justify-center'>
                  <div className='max-w-xl mb-6 wow fadeInLeft' data-wow-delay='0.1s'>
                    <h2 className='max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none'>
                      Një tekst per {' '}
                      <br className='hidden md:block' />
                      Art Roleten{' '}
                      <span className='inline-block text-jertt'>
                         ketu
                      </span>
                    </h2>
                    <p className='text-base text-gray-700 md:text-lg'>
                    ART ROLETA ju bën zgjidhjen më të mirë sa i përket roletave dhe perdeve të ndryshme, me modele dhe dizajne unike. -- shembull
                    </p>
                  </div>
                  <div className='grid gap-8 row-gap-8 sm:grid-cols-2'>
                    <div className='wow fadeInUp' data-wow-delay='0.2s'>
                      <div className='flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50'>
                        <AiOutlineCheckCircle className='w-7 h-7 text-jertt '/>
                      </div>
                      <h6 className='mb-2 font-semibold leading-5'>
                        Cilësi e garantuar
                      </h6>
                      <p className='text-sm text-gray-900'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </p>
                    </div>
                    <div className='wow fadeInUp' data-wow-delay='0.2s'>
                      <div className='flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50'>
                        <AiOutlineSetting className='w-7 h-7 text-jertt' />
                      </div>
                      <h6 className='mb-2 font-semibold leading-5'>
                        Lehtë për t'u përdorur
                      </h6>
                      <p className='text-sm text-gray-900'>
                        
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. 
                      </p>
                    </div>
                  </div>
                </div>
                <div className='wow fadeInDown' data-wow-delay='0.2s'>
                  <img className='object-cover w-full h-56 shadow-lg sm:h-96 rounded-tr-[60px] rounded-bl-[60px]' src={Blinds1} alt=''/>
                </div>
              </div>
              <div className='grid gap-12 row-gap-8 lg:grid-cols-2 py-12'>
                <div className='wow fadeInLeft' data-wow-delay='0.2s'>
                  <img className='object-cover w-full h-56 shadow-lg sm:h-96 rounded-tr-[60px] rounded-bl-[60px]' src={Blinds2} alt=''/>
                </div>
                <div className='wow fadeInRight' data-wow-delay='0.2s'>
                  <img className='object-cover w-full h-56 shadow-lg sm:h-96 rounded-tr-[60px] rounded-bl-[60px]' src={Blinds3} alt=''/>
                </div>
              </div>
            </div>
        </section>
      </>
    )
  }
}
