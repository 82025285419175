import React, { Component } from 'react'

import WOW from 'wowjs';

export default class Tex extends Component {


// Defining WOW 
componentDidMount() {
    new WOW.WOW({
      live: false
  }).init();
}


  render() {
    return (
      <></>
    )
  }
}
