import React from 'react'
import Xhama from '../components/Xhama'
function xhama() {
  return (
    <>
      <Xhama />
    </>
  )
}

export default xhama