import Logo from '../assets/logo.svg';
import React, { useState } from "react";

import { Link } from 'react-router-dom';
// import { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu, AiOutlineInstagram } from 'react-icons/ai'
import { BsFacebook } from 'react-icons/bs'

const Navbar = () => {
// Mobile nav menu
const [nav, setNav] = useState(false);

const handleNav = () => {
  setNav(!nav);
}
// Mobile nav menu END

// change navbar background color when scrolling
const [color, setColor] = useState(false)
const changeColor = () => {
  if (window.scrollY >= 100) {
    setColor(true)
  } else {
    setColor(false)
  }
}

window.addEventListener('scroll', changeColor)

  return (
      <>
      <div className={color ? 'sticky bg-jertt top-0 z-50 ease-in duration-300 overflow-hidden shadow-sm shadow-gray-500' : 'sticky bg-white top-0 z-50 ease-in duration-500 overflow-hidden shadow-sm shadow-gray-300'}>
        <nav className='bg-texx13'>
          <div className="flex justify-between lg:justify-around items-center mx-auto px-8 sm:px-8 md:px-14 lg:px-20 2xl:px-[3rem] shadow-sm w-screen  overflow-hidden py-4 lg:py-0">
            

              <ul className={color ? 'hidden lg:flex py-3 font-medium text-white' : 'hidden lg:flex py-3 font-medium text-jertt-800'}>
                  <li className='p-4'><Link to="/">Ballina</Link></li>
                  <li className='p-4'><Link to="/galeria">Galeria</Link></li>
                  <li className='p-4'><Link to="/sherbime">Sherbime</Link></li>
                  <li className='p-4 truncate'><Link to="/rreth_ne">Rreth Ne</Link></li>
                  <li className='p-4'><Link to="/kontakt">Kontakti</Link></li>
                  {/* <li className='px-12'><Link to="/kontakt"></Link></li> */}
              </ul>

              <a href="/" className='select-none'><img src={Logo} className='w-48' alt="Logo of Auto Japan | Auto Pjesë" /></a>

              <ul className={color ? 'hidden lg:flex py-3 font-medium text-white' : 'hidden lg:flex py-3 font-medium text-jertt-800'}>
                  {/* <li className='px-8'></li> */}
                  {/* <li className='px-8'></li> */}
                  <li className='px-12'></li>
                  <li className='px-11'></li>
                  <li className='px-10'></li>
                  <li className='px-10'></li>
                  <a href='https://www.facebook.com/artroleta' target='_blank' rel='noreferrer'><li className='p-4 '><BsFacebook size={26} /></li></a>
                  <a href='https://www.instagram.com/artroleta' target='_blank' rel='noreferrer'><li className='p-4 '><AiOutlineInstagram size={26} /></li></a>
              </ul>
              {/* ========= Mobile Nav Start ========= */}
            <div onClick={handleNav} className={color ? 'text-zinc-100 block lg:hidden' : 'text-jertt-700 block lg:hidden'}>
              {nav ? <AiOutlineClose size={26} /> : <AiOutlineMenu size={26} />} 
            <div className={nav ? 'fixed w-[40%] right-0 top-0 h-full' : 'bg-red-400'}></div>
            </div>            
            <div className={nav ? 'fixed lg:hidden left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-jertt-600 text-white py-4 ease-in-out duration-500' : 'fixed left-[-100%] '}>

            <img src={Logo} className='w-48 mx-auto' alt="" />

              <ul className='pt-12 p-4'>
                  <Link to="/" onClick={() => setNav(false)}><li className='p-4 border-b border-gray-400'>Ballina</li></Link>
                  <Link to="/sherbime" onClick={() => setNav(false)}><li className='p-4 border-b border-gray-400'>Sherbime</li></Link>
                  <Link to="/rreth_ne" onClick={() => setNav(false)}><li className='p-4 border-b border-gray-400'>Rreth Ne</li></Link>
                  <Link to="/kontakt" onClick={() => setNav(false)}><li className='p-4 border-b border-gray-400'>Kontakti</li></Link>
                  <div className="flex justify-start">
                    <a href='https://www.instagram.com/artroleta' target='_blank' rel='noreferrer'><li className='p-4'><AiOutlineInstagram size={26} /></li></a>
                    <a href='https://www.facebook.com/artroleta' target='_blank' rel='noreferrer'><li className='p-4'><BsFacebook size={26} /></li></a>
                  </div>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      </>
)
}

export default Navbar;