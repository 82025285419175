import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Rol1 from '../assets/details/roleta/rol1.webp'
import Rol2 from '../assets/details/roleta/rol2.webp'
import Rol3 from '../assets/details/roleta/rol3.webp'
import Rol4 from '../assets/details/roleta/rol4.webp'
import Tex from './Tex'

export default class Roleta extends Component {
  render() {
    return (
      <>
      <Tex />
        <section className='relative md:py-32 py-16 flex justify-center bg-gray-100/75  overflow-hidden'>
          <div className='container'>
              <div className='grid grid-cols-1 pb-8 text-center'>
                    <h6 className='text-jertt-600 text-sm font-bold uppercase mb-2 -mt-9 wow fadeInDown' data-wow-delay='0.1s'>Garancion 5 vite.</h6>
                    <h3 className='mb-4 md:text-3xl md:leading-normal text-2xl text-jertt-800 leading-normal font-semibold wow fadeInUp' data-wow-delay='0.2s'>Roleta të jashtme:</h3>
                    <p className='text-muted-400 max-w-xl mx-auto px-8 text-jertt-600 wow fadeInUp' data-wow-delay='0.2s'>Roleta të jashtme: <span className='font-semibold'> Pllastik {'('}PVC{')'} </span> dhe <span className='font-semibold'> Alumin</span></p>
              <div className='container px-5 py-24 mx-auto'>
                <div className='flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2'>
                  <div className='p-2 sm:w-1/2 w-full wow fadeInLeft' data-wow-delay='0.1s'>
                    <div className='bg-gray-200/75 rounded flex p-4 h-full items-center'>
                      <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6 flex-shrink-0 mr-4' viewBox='0 0 24 24'>
                        <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                        <path d='M22 4L12 14.01l-3-3'></path>
                      </svg>
                      <span className='title-font font-medium px-4'>Roleta të jashtme <span className='font-semibold'> - Pllastik {'('}PVC{')'} ngjyrë e bardhë.</span></span>
                    </div>
                  </div>
                  <div className='p-2 sm:w-1/2 w-full wow fadeInRight' data-wow-delay='0.2s'>
                    <div className='bg-gray-200/75 rounded flex p-4 h-full items-center'>
                      <svg fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' className='text-jertt w-6 h-6 flex-shrink-0 mr-4' viewBox='0 0 24 24'>
                        <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                        <path d='M22 4L12 14.01l-3-3'></path>
                      </svg>
                      <span className='title-font font-medium'>Roleta të jashtme <span className='font-semibold'> - Alumin {'('}ngjyrë e bardhë &amp; hiri, antracit, goldenoak, arrë{')'}.</span></span>
                    </div>
                    </div>
                </div>
              <section className=''>
                <div className='px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8'>
                  <div className='grid gap-12 row-gap-8 lg:grid-cols-2 py-12'>
                    <div className='wow fadeInLeft' data-wow-delay='0.2s'>
                      <img className='object-cover w-full h-56 shadow-lg sm:h-96 rounded-tr-[60px] rounded-bl-[60px]' src={Rol1} alt=''/>
                    </div>
                    <div className='wow fadeInRight' data-wow-delay='0.2s'>
                      <img className='object-cover w-full h-56 shadow-lg sm:h-96 rounded-tr-[60px] rounded-bl-[60px]' src={Rol2} alt=''/>
                    </div>
                    <div className='wow fadeInLeft' data-wow-delay='0.2s'>
                      <img className='object-cover w-full h-56 shadow-lg sm:h-96 rounded-tr-[60px] rounded-bl-[60px]' src={Rol3} alt=''/>
                    </div>
                    <div className='wow fadeInRight' data-wow-delay='0.2s'>
                      <img className='object-cover w-full h-56 shadow-lg sm:h-96 rounded-tr-[60px] rounded-bl-[60px]' src={Rol4} alt=''/>
                    </div>
                  </div>
                </div>
              </section>
                <button className='flex mx-auto mt-16 text-white bg-jertt border-0 py-2 px-8 focus:outline-none hover:bg-jertt-700 ease-linear duration-300 rounded text-lg wow fadeInUp' data-wow-delay='0.2s'><Link to="/kontakt">Na kontaktoni tani!</Link></button>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}
