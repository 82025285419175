const Images = [
     {
        id: 1,
        image: "./galeria-webp/roleta-te-jashtme-alumini.webp",
        name:"Roleta te jashtme alumini",
        category: "roleta",
        // price: "12₹",
        description: 'Roleta te jashtme alumini nga Art Roleta'
    },
  
    {
        id: 2,
        image: "./galeria-webp/roleta-te-jashtme-alumini-2.webp",
        name:"Roleta te jashtme alumini",
        category: "roleta",
        // price: "20₹",
        description: 'Roleta te jashtme alumini nga Art Roleta'
    },
    {
        id: 3,
        image: "./galeria-webp/dgarazhdave-77mm.webp",
        name:"Dyer te Garazhdave 77mm",
        category: "dyer",
        // price: "10₹",
        description: 'Dyer te Garazhdave 77mm nga Art Roleta'
    },
     {
        id: 4,
        image: "./galeria-webp/d-harmonike.webp",
        name:"Dyer Harmonike",
        category: "dyer",
        // price: "50₹",
        description: 'Dyer Harmonike nga Art Roleta'
    },
      {
        id: 5,
        image: "./galeria-webp/pbambus.webp",
        name:"Perde Bambus",
        category: "perde",
        // price: "80₹",
        description: 'Perde Bambus nga Art Roleta'
    },
       {
        id: 6,
        image: "./galeria-webp/phanas.webp",
        name:"Perde Hanas",
        category: "perde",
        // price: "180₹",
        description: 'Perde Hanas nga Art Roleta'
    },
        {
        id: 7,
        image: "./galeria-webp/pplise.webp",
        name:"Perde Plise",
        category: "perde",
        // price: "60₹",
        description: 'Perde Plise nga Art Roleta'
    },
        {
        id: 8,
        image: "./galeria-webp/prolle.webp",
        name:"Perde Rolle",
        category: "perde",
        // price: "60₹",
        description: 'Perde Rolle nga Art Roleta'
    },
        {
        id: 9,
        image: "./galeria-webp/pshangrila.webp",
        name:"Perde Shangrila",
        category: "perde",
        // price: "60₹",
        description: 'Perde Shangrila nga Art Roleta'
    },
        {
        id: 10,
        image: "./galeria-webp/pshiritore.webp",
        name:"Perde Shiritore",
        category: "perde",
        // price: "60₹",
        description: 'Perde Shiritore nga Art Roleta'
    },
        {
        id: 11,
        image: "./galeria-webp/pzeber.webp",
        name:"Perde Zeber",
        category: "perde",
        // price: "60₹",
        description: 'Perde Zeber nga Art Roleta'
    },
        {
        id: 12,
        image: "./galeria-webp/valumin-25mm.webp",
        name:"Veneciane Alumin (25mm)",
        category: "veneciane",
        // price: "60₹",
        description: 'Veneciane Alumin (25mm) nga Art Roleta'
    },
        {
        id: 13,
        image: "./galeria-webp/valumin-50mm.webp",
        name:"Veneciane Alumin (50mm)",
        category: "veneciane",
        // price: "60₹",
        description: 'Veneciane Alumin (50mm) nga Art Roleta'
    },
        {
        id: 14,
        image: "./galeria-webp/vdruri.webp",
        name:"Veneciane Druri",
        category: "veneciane",
        // price: "60₹",
        description: 'Veneciane Druri nga Art Roleta'
    },
        {
        id: 15,
        image: "./galeria-webp/xhamalevizes.webp",
        name:"Xhama Lëvizës",
        category: "xhama",
        // price: "60₹",
        description: 'Xhama Lëvizës nga Art Roleta'
    },
        {
        id: 16,
        image: "./galeria-webp/xhama-slide-rreshqites.webp",
        name:"Xhama sllide-rrëshqitës",
        category: "xhama",
        // price: "60₹",
        description: 'Xhama sllide-rrëshqitës nga Art Roleta'
    },
];



export default Images;