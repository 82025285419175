import React, {useState} from 'react'
import Images from './images';
import Tex from './Tex';

export const Lightbox = () => {

  const [items, setItems] = useState(Images);
    
  const filterItem = (categItem) => {
      const updatedItems = Images.filter((curElem) => {
          return curElem.category === categItem;
      });

      setItems(updatedItems);
  }

  return (
        <>
        <Tex />
          <section className='relative md:py-32 py-16 flex justify-center bg-gray-100/75 min-h-screen'>
            <div className='container'>
                <div className='grid grid-cols-1 pb-8 text-center'>
                    <h6 className='text-jertt-600 text-sm font-bold uppercase mb-2 -mt-9 wow fadeInDown' data-wow-delay='0.1s'>Galeria e produktev</h6>
                    <h3 className='mb-4 md:text-3xl md:leading-normal text-2xl text-jertt-800 leading-normal font-semibold wow fadeInUp' data-wow-delay='0.2s'>Produktet tona!</h3>
                    <p className='text-muted-400 max-w-xl mx-auto px-4 text-jertt-600 wow fadeInUp' data-wow-delay='0.2s'>Lorem ipsum dolor sit amet consectetur adipisicing elit!</p>
                </div>
                <div className='text-center flex justify-center'>
                        <div className='py-4 wow fadeInUp' data-wow-delay='0.3s'>
                            <ul className='mb-0 list-none'>
                                <li className='inline-block font-medium text-lg mx-2 mb-3 cursor-pointer relative text-smoothblck transition duration-500' onClick={() => setItems(Images)}>Të Gjitha</li>
                                <li className='inline-block font-medium text-lg mx-2 mb-3 cursor-pointer relative text-smoothblck transition duration-500' onClick={() => filterItem('roleta')}>Roleta</li>
                                <li className='inline-block font-medium text-lg mx-2 mb-3 cursor-pointer relative text-smoothblck transition duration-500' onClick={() => filterItem('dyer')}>Dyer</li>
                                <li className='inline-block font-medium text-lg mx-2 mb-3 cursor-pointer relative text-smoothblck transition duration-500' onClick={() => filterItem('perde')}>Perde</li>
                                <li className='inline-block font-medium text-lg mx-2 mb-3 cursor-pointer relative text-smoothblck transition duration-500' onClick={() => filterItem('veneciane')}>Veneciane</li>
                                <li className='inline-block font-medium text-lg mx-2 mb-3 cursor-pointer relative text-smoothblck transition duration-500' onClick={() => filterItem('xhama')}>Xhama</li>
                            </ul>
                        </div>
                    </div>
                <div className='grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-6 md:mt-8 px-8 md:p-2'>
                  
                      {/* <div className='group relative block overflow-hidden rounded-md transition-all duration-500 wow fadeInUp' data-wow-delay='0.1s'>
                          <img src={Blinds3} className='group-hover:scale-110 transition duration-500' alt=''/>
                        <div className='absolute inset-0 bg-smoothblck opacity-20 z-0'></div>
                        <div className='absolute -bottom-52 group-hover:bottom-2 right-2 left-2 transition-all duration-500 bg-white p-4 rounded shadow'>
                          <p className='hover:text-jertt-600 text-lg transition duration-500 font-medium'>Iphone mockup</p>
                          <h6 className='text-muted-400'>Branding</h6>
                        </div>
                        <div className='lg:hidden absolute bottom-2 right-2 left-2 transition-all duration-500 bg-white p-4 rounded shadow'>
                          <p className='hover:text-jertt-600 text-lg transition duration-500 font-medium'>Iphone mockup</p>
                          <h6 className='text-muted-400'>Branding</h6>
                        </div>
                      </div> */}

                      {
                        items.map((elem) => {
                          const { name, image } = elem;

                            return (
                                    
                              <div className='relative block hover:scale-110 overflow-hidden rounded-tr-[30px] rounded-bl-[30px] transition-all duration-500 mt-2 wow fadeInUp' data-wow-delay='0.1s'>
                                {/* For Image */}
                                  <img src={image} className='transition duration-500' alt={name}/>
                                <div className='absolute inset-0 bg-smoothblck opacity-20 z-0'></div>
                                {/* <div className='absolute -bottom-52 group-hover:bottom-2 right-2 left-2 transition-all duration-500 bg-white p-4 rounded shadow'>
                                  <p className='hover:text-jertt-600 text-lg transition duration-500 font-medium'>{name}</p>
                                  <h6 className='text-muted-400'>{description }</h6>
                                </div>
                                <div className='lg:hidden absolute bottom-2 right-2 left-2 transition-all duration-500 bg-white p-4 rounded shadow'>
                                  <p className='hover:text-jertt-600 text-lg transition duration-500 font-medium'>{name}</p>
                                  <h6 className='text-muted-400'>{description }</h6>
                                </div> */}
                              </div>
                            )
                          })
                        }

                      
                </div>
            </div>
          </section>
        </>
  )
}
export default Lightbox;