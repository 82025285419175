import React from 'react'
import Roleta from '../components/Roleta'
function roleta() {
  return (
    <>
      <Roleta />
    </>
  )
}

export default roleta