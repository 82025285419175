import React, { Component } from 'react'
import Roleta from '../assets/prods/roleta-te-jashtme-alumini-2.webp'
import Perde from '../assets/prods/pbambus.webp'
import Dyer from '../assets/prods/d-harmonike.webp'
import Veneciane from '../assets/prods/vdruri.webp'
import { Link } from 'react-router-dom';

export default class Prods extends Component {
  render() {
    return (
        <>
          <section className='relative md:py-24 py-16 flex justify-center bg-gray-100/75 overflow-hidden'>
            <div className='container'>
                <div className='grid grid-cols-1 pb-8 text-center'>
                    <h3 className='mb-4 md:text-3xl md:leading-normal text-2xl text-jertt-800 leading-normal font-semibold wow fadeInUp' data-wow-delay='0.2s'>Disa nga produktet tona!</h3>
                    <p className='text-muted-400 max-w-xl mx-auto px-4 text-jertt-600 wow fadeInUp' data-wow-delay='0.2s'>Vizitoni sektorin tonë të galerisë për të parë të gjitha produktet tona</p>
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-6 md:mt-8 px-8 md:p-2'>
                    <div className='relative block hover:scale-110 overflow-hidden rounded-tr-[30px] rounded-bl-[30px] transition-all duration-500 mt-2 wow fadeInLeft' data-wow-delay='0.1s'>
                        {/* For Image */}
                            <img src={Roleta} className='transition duration-500' alt="/"/>
                        <div className='absolute inset-0 bg-smoothblck opacity-20 z-0'></div>
                    </div>
                    <div className='relative block hover:scale-110 overflow-hidden rounded-tr-[30px] rounded-bl-[30px] transition-all duration-500 mt-2 wow fadeInUp' data-wow-delay='0.1s'>
                        {/* For Image */}
                            <img src={Perde} className='transition duration-500' alt="/"/>
                        <div className='absolute inset-0 bg-smoothblck opacity-20 z-0'></div>
                    </div>
                    <div className='relative block hover:scale-110 overflow-hidden rounded-tr-[30px] rounded-bl-[30px] transition-all duration-500 mt-2 wow fadeInUp' data-wow-delay='0.1s'>
                        {/* For Image */}
                            <img src={Dyer} className='transition duration-500' alt="/"/>
                        <div className='absolute inset-0 bg-smoothblck opacity-20 z-0'></div>
                    </div>
                    <div className='relative block hover:scale-110 overflow-hidden rounded-tr-[30px] rounded-bl-[30px] transition-all duration-500 mt-2 wow fadeInRight' data-wow-delay='0.1s'>
                        {/* For Image */}
                            <img src={Veneciane} className='transition duration-500' alt="/"/>
                        <div className='absolute inset-0 bg-smoothblck opacity-20 z-0'></div>
                    </div>
                </div>
                <button className="flex mx-auto mt-16 text-white bg-jertt border-0 py-2 px-8 focus:outline-none hover:bg-jertt-700 ease-linear duration-300 rounded text-lg"><Link to="/galeria">Shikoni të gjitha</Link></button>
            </div>
          </section>
        </>
    )
  }
}
