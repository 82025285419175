// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import swipe from './swipe.png'
import Slide1 from '../assets/swipe/swiper1.webp'
// import Slide2 from '../assets/swipe/swiper3.webp'
import Slide3 from '../assets/swipe/vene.webp'
import Slide4 from '../assets/swipe/swiper4.webp'

// Import Swiper styles
import 'swiper/css';
// import "swiper/css/effect-creative";

import { Autoplay, EffectCreative } from "swiper";

export default function HeroSlider() {
  return (
  
    <Swiper
    spaceBetween={0}
    slidesPerView={1}
    speed={1500}
    simulateTouch={false}
    autoplay={{
      delay: 3000,
      disableOnInteraction: false,
    }}
    effect={"creative"}
    creativeEffect={{
      prev: {
        // shadow: true,
        translate: ["-20%", 0, -1],
      },
      next: {
        translate: ["100%", 0, 0],
      },
    }}
    loop={true}
    modules={[Autoplay, EffectCreative]}
    onSlideChange={() => console.log('slide change')}
    onSwiper={(swiper) => console.log(swiper)}
  >
    <SwiperSlide><div className='overflow-hidden bg-no-repeat bg-center bg-cover relative -z-10 lg:h-[550px] md:h-[550px] h-[400px] select-none'
    style={{
      backgroundImage: `url(${Slide1})`,
      // backgroundImage: `url(${externalImage})`,
      // height: '700px',
      // backgroundPositionY: '5%',
    }}
    >
    <div className='absolute inset-0 bg-black/40 overflow-hidden -z-10'></div>
      {/* <div className='absolute inset-0 bg-black/70 z-0 h-[600px]'></div> */}
        <div className='max-w-[800px] w-full mx-auto pb-8 z-50 text-center flex items-center justify-center lg:h-[550px] md:h-[550px] h-[400px]'>
            {/* <p className='text-zinc-100 font-semibold'>Lokacioni: Fushë Kosovë dhe Pejë</p> */}
            {/* <h1 className='text-gray-100 text-6xl md:text-7xl sm:text-6xl font-black md:py-4'><span className='text-gray-200'>AUTO</span> JAPAN</h1> */}
            <div>
                <h2 className='lg:text-5xl md:text-4xl sm:text-4xl text-2xl font-medium text-white px-4 wow fadeInUp' data-wow-delay='0.1s'>Ruaj freskinë e ambientit tuaj!</h2>
            </div>
        </div>
    </div></SwiperSlide>
    <SwiperSlide><div className='overflow-hidden bg-no-repeat bg-center bg-cover relative -z-10 lg:h-[550px] md:h-[550px] h-[400px] select-none'
    style={{
      backgroundImage: `url(${Slide3})`,
      // backgroundImage: `url(${externalImage})`,
      // height: '700px',
      // backgroundPositionY: '5%',
    }}
    >
    <div className='absolute inset-0 bg-black/40 overflow-hidden -z-10'></div>
      {/* <div className='absolute inset-0 bg-black/70 z-0 h-[600px]'></div> */}
        <div className='max-w-[800px] w-full mx-auto z-50 text-center flex items-center justify-center lg:h-[550px] md:h-[550px] h-[400px]'>
            {/* <p className='text-zinc-100 font-semibold'>Lokacioni: Fushë Kosovë dhe Pejë</p> */}
            {/* <h1 className='text-gray-100 text-6xl md:text-7xl sm:text-6xl font-black md:py-4'><span className='text-gray-200'>AUTO</span> JAPAN</h1> */}
            <div>
                <h2 className='lg:text-5xl md:text-4xl sm:text-4xl text-2xl font-medium text-white px-4 wow fadeInUp' data-wow-delay='0.1s'>Kurrë nuk është vonë për ndryshime.</h2>
            </div>
        </div>
    </div></SwiperSlide>
    <SwiperSlide><div className='overflow-hidden bg-no-repeat bg-center bg-cover relative -z-10 lg:h-[550px] md:h-[550px] h-[400px] select-none'
    style={{
      backgroundImage: `url(${Slide4})`,
      // backgroundImage: `url(${externalImage})`,
      // height: '700px',
      // backgroundPositionY: '5%',
    }}
    >
    <div className='absolute inset-0 bg-black/40 overflow-hidden -z-10'></div>
      {/* <div className='absolute inset-0 bg-black/70 z-0 h-[600px]'></div> */}
        <div className='max-w-[800px] w-full mx-auto z-50 text-center flex items-center justify-center lg:h-[550px] md:h-[550px] h-[400px]'>
            {/* <p className='text-zinc-100 font-semibold'>Lokacioni: Fushë Kosovë dhe Pejë</p> */}
            {/* <h1 className='text-gray-100 text-6xl md:text-7xl sm:text-6xl font-black md:py-4'><span className='text-gray-200'>AUTO</span> JAPAN</h1> */}
            <div>
                <h2 className='lg:text-5xl md:text-4xl sm:text-4xl text-2xl font-medium text-white px-4 wow fadeInUp' data-wow-delay='0.1s'>Zgjedhja perfekte për shtëpinë tuaj!</h2>
            </div>
        </div>
    </div></SwiperSlide>
    {/* <SwiperSlide><img src={ swipe } alt="/" className='h-[45rem] object-cover w-screen overflow-hidden' /></SwiperSlide> */}
    {/* <SwiperSlide><img src={ swwipe } alt="/" className='h-[45rem] object-cover w-screen overflow-hidden' /></SwiperSlide> */}
    {/* <SwiperSlide><img src={ swwipe } alt="/" className='h-[45rem] object-cover w-screen overflow-hidden' /></SwiperSlide> */}
    
  </Swiper>

  )
}
