import React from 'react'
import Contact from '../components/Contact'

const contact = () => {
  return (
    <>
     <Contact />
    </>
  )
}

export default contact